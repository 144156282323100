import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-termsofuse',
  templateUrl: './termsofuse.component.html',
  styleUrls: ['./termsofuse.component.css'],
})
export class TermsofuseComponent implements OnInit {
  constructor(public translate: TranslateService) {}

  contentFirst: string = '';

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.contentFirst = this.translate.instant('termOfUseConditionText1');
  }
}
