<div class="container kvkk-container-fix">
  <div class="row">
    <div class="col-lg-4">
      <div class="list-group" id="list-tab" role="tablist">
        <ng-container *ngFor="let link of links; let i = index">
          <a
            class="list-group-item list-group-item-action"
            [ngClass]="{ active: applyActiveClass(link) }"
            id="{{ 'list-' + link.contentId + '-list' }}"
            data-toggle="list"
            href="{{ '#list-' + link.contentId }}"
            role="tab"
            attr.aria-controls="{{ link.contentId }}"
            >{{ link.title | translate }}</a
          >
        </ng-container>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="tab-content" id="nav-tabContent">
        <ng-container *ngFor="let link of links; let i = index">
          <div
            class="tab-pane fade show kvkk-content"
            [ngClass]="{ 'show active': applyActiveClass(link) }"
            id="{{ 'list-' + link.contentId }}"
            role="tabpanel"
            attr.aria-labelledby="{{ 'list-' + link.contentId + '-list' }}"
            [innerHTML]="link.page"
          ></div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
