import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewEncapsulation,
  AfterViewChecked,
  Inject,
} from '@angular/core';
import {
  Event,
  NavigationStart,
  Router,
  ActivatedRoute,
  NavigationEnd,
} from '@angular/router';
import { Location, DOCUMENT } from '@angular/common';
import { CommonServiceService } from './common-service.service';
import { filter } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { LanguageService } from './services/language.service';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  //changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterViewChecked {
  title = 'vera-doctors';
  url;
  loadFooter = false;
  show: boolean = true;
  hideFooter: boolean = false;

  cookieConsentShow: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private activeRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    public Router: Router,
    location: Location,
    public commonServic: CommonServiceService,
    private authService: AuthService,
    private languageSer: LanguageService
  ) {
    Router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (
          event.url == '/login' ||
          event.url == '/panel' ||
          event.url == '/forgot-password' ||
          event.url == '/Register'
        ) {
          document.querySelector('body').classList.add('account-page');
          document.querySelector('body').classList.remove('mat-typography');
          if (event.url == '/login') {
            this.Router.navigateByUrl('/login');
            this.Router.navigateByUrl('/panel');
          }
        } else {
          document.querySelector('body').classList.remove('account-page');
          document.querySelector('body').classList.add('mat-typography');
        }
        if (event.url == '/map-grid') {
          this.hideFooter = true;
        }
        if (
          event.url == '/video-call' ||
          event.url == '/voice-call' ||
          event.url == '/agora-calls'
        ) {
          document.querySelector('body').classList.add('call-page');
          document.querySelector('body').classList.remove('mat-typography');
        } else {
          document.querySelector('body').classList.remove('call-page');
          document.querySelector('body').classList.add('mat-typography');
        }
        if (event.url == '/agora-calls') {
          document.querySelector('body').classList.add('call-page');
          document.querySelector('body').classList.remove('mat-typography');
        } else {
          document.querySelector('body').classList.remove('call-page');
          document.querySelector('body').classList.add('mat-typography');
        }
      }
    });
    this.url = location.path();
    this.show = this.url.includes('admin') ? false : true;
    this.show = this.url.includes('pharmacy-admin') ? false : true;
    this.show = this.url.includes('call-center') ? false : true;
    this.show = this.url.includes('agora-calls') ? false : true;
    this.show = this.url.includes('agora-login') ? false : true;
    this.hideFooter = this.url.includes('agora-login') ? true : false;
    this.show = this.url.includes('thy-admin') ? false : true;

    /**
     * Hide header for call
     */
    this.show = this.url.includes('call') ? false : true;

    this.commonServic.message.subscribe((res) => {
      if (
        res === 'adminLogin' ||
        res === 'admin' ||
        res === 'pharmacy-admin' ||
        res === 'callCenter' ||
        res === 'hospital-admin' ||
        res === 'thy-admin'
      ) {
        this.show = false;
        this.hideFooter = true;
      } else if (res === 'main') {
        this.show = true;
        this.hideFooter = false;
      } else if (res === 'chat') {
        this.show = true;
        this.hideFooter = true;
      } else if (res === 'call') {
        /**
         * Hide header for call
         */
        this.show = false;
        this.hideFooter = true;
      } else {
        this.show = true;
        this.hideFooter = false;
      }
    });
  }

  ngOnInit() {
    this.languageSer.getSelectedLanguage().subscribe((localLang) => {
      this.document.documentElement.lang = localLang || 'en';
    });

    this.setUpAnalytics();

    this.authService.userLoggedIn$.subscribe((isLoggedIn) => {
      this.cookieConsentShow = !isLoggedIn;
      this.changeDetector.detectChanges();
    });

    this.show = this.url.includes('agora-login') ? false : true;
    this.hideFooter = this.url.includes('agora-login') ? true : false;

    setTimeout(() => (this.loadFooter = true), 2000);
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  setUpAnalytics() {
    this.Router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', 'G-EHCWGRVZ78', {
          page_path: event.urlAfterRedirects,
        });
      });
  }
}
