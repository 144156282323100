import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule,
  PreloadAllModules,
  CanActivate,
} from '@angular/router';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';

import { RoleGuardService as RoleGuard } from './auth/role-guard.service';
import { BecomeProviderComponent } from './information-pages/become-provider/become-provider.component';
import { GovernmentComponent } from './information-pages/businesses/government/government.component';
import { HospitalAndClinicsComponent } from './information-pages/businesses/hospital-and-clinics/hospital-and-clinics.component';
import { HotelsRentalsToursComponent } from './information-pages/businesses/hotels-rentals-tours/hotels-rentals-tours.component';
import { PrivateTransportCompaniesComponent } from './information-pages/businesses/private-transport-companies/private-transport-companies.component';
import { WhatWeTreatComponent } from './information-pages/patients/what-we-treat/what-we-treat.component';
import { BlogComponent } from './information-pages/company/blog/blog.component';
import { ExecutiveTeamComponent } from './information-pages/company/executive-team/executive-team.component';
import { PartnersComponent } from './information-pages/company/partners/partners.component';
import { WhoWeAreComponent } from './information-pages/company/who-we-are/who-we-are.component';
import { DisclaimerComponent } from './information-pages/disclaimer/disclaimer.component';
import { InformedConsentComponent } from './information-pages/informed-consent/informed-consent.component';
import { FrequentlyAskedQuestionsComponent } from './information-pages/patients/frequently-asked-questions/frequently-asked-questions.component';
import { HowItWorksComponent } from './information-pages/patients/how-it-works/how-it-works.component';
import { PrivacyPolicyComponent } from './information-pages/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './information-pages/terms-of-use/terms-of-use.component';
import { WorkInProgressComponent } from './information-pages/work-in-progress/work-in-progress.component';
import { OurProvidersComponent } from './information-pages/patients/our-providers/our-providers.component';
import { PharmaciesLabsComponent } from './information-pages/businesses/pharmacies-labs/pharmacies-labs.component';
import { CovidComponent } from './information-pages/patients/covid/covid.component';
import { InsuranceComponent } from './information-pages/businesses/insurance/insurance.component';
import { TravelComponent } from './information-pages/travel/travel.component';
import { ThyComponent } from './information-pages/company/thy/thy.component';
import { KVKKComponent } from './information-pages/kvkk/kvkk.component';
import { AvicernaComponent } from './avicerna/avicerna.component';
import { ObesityComponent } from './obesity/obesity.component';

/*// src/app/app.routes.ts
import { Routes, CanActivate } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import {
  AuthGuardService as AuthGuard
} from './auth/auth-guard.service';
import {
  RoleGuardService as RoleGuard
} from './auth/role-guard.service';
export const ROUTES: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  { path: '**', redirectTo: '' }
];
*/

/*import { Routes, CanActivate } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
export const ROUTES: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: '' }
];*/

const routes: Routes = [
  // {
  //   path: 'home',
  //   loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  // },
  // {
  //   path: 'doctor-profile',
  //   loadChildren: () =>
  //     import('./patients/doctor-profile/doctor-profile.module').then(
  //       (m) => m.DoctorProfileModule
  //     ),
  // },
  // {
  //   path: 'home-one',
  //   loadChildren: () =>
  //     import('./home-one/home-one.module').then((m) => m.HomeOneModule),
  // },
  // {
  //   path: 'home-two',
  //   loadChildren: () =>
  //     import('./home-two/home-two.module').then((m) => m.HomeTwoModule),
  // },
  {
    path: 'home-page',
    loadChildren: () =>
      import('./home-slider-one/home-slider-one.module').then(
        (m) => m.HomeSliderOneModule
      ),
  },
  // {
  //   path: 'home-slider-two',
  //   loadChildren: () =>
  //     import('./home-slider-two/home-slider-two.module').then(
  //       (m) => m.HomeSliderTwoModule
  //     ),
  // },
  /* {
    path: 'doctor',
    loadChildren: () =>
      import('./doctor/doctor.module').then((m) => m.DoctorModule),
  },
  {
    path: 'patients',
    loadChildren: () =>
      import('./patients/patients.module').then((m) => m.PatientsModule),
  },*/
  {
    path: 'pharmacy',
    loadChildren: () =>
      import('./pharmacy/pharmacy.module').then((m) => m.PharmacyModule),
  },
  {
    path: 'blank',
    loadChildren: () =>
      import('./blank/blank.module').then((m) => m.BlankModule),
  },
  {
    path: 'map-grid',
    loadChildren: () =>
      import('./map-grid/map-grid.module').then((m) => m.MapGridModule),
  },
  {
    path: 'map-list',
    loadChildren: () =>
      import('./map-list/map-list.module').then((m) => m.MapListModule),
  },
  {
    path: 'calender',
    loadChildren: () =>
      import('./calender/calender.module').then((m) => m.CalenderModule),
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule),
  },
  {
    path: 'blog-grid',
    loadChildren: () =>
      import('./blog-grid/blog-grid.module').then((m) => m.BlogGridModule),
  },
  {
    path: 'blank',
    loadChildren: () =>
      import('./blank/blank.module').then((m) => m.BlankModule),
  },
  {
    path: 'blog-details/:id',
    loadChildren: () =>
      import('./blog-details/blog-details.module').then(
        (m) => m.BlogDetailsModule
      ),
  },
  {
    path: 'search-doctor',
    loadChildren: () =>
      import('./search-doc/search-doc.module').then((m) => m.SearchDocModule),
  },
  {
    path: 'membership',
    loadChildren: () =>
      import('./membership/membership.module').then((m) => m.MemebershipModule),
  },
  {
    path: 'doctor-profile/:id',
    loadChildren: () =>
      import('./doctor-profile/doctor-profile.module').then(
        (m) => m.DoctorProfileModule
      ),
  },
  {
    path: 'panel',
    loadChildren: () =>
      import('./admin-login/admin-login.module').then(
        (m) => m.AdminLoginModule
      ),
  },
  {
    path: 'login-page',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'Register',
    loadChildren: () =>
      import('./register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'video-call',
    loadChildren: () =>
      import('./videocall/videocall.module').then((m) => m.VideocallModule),
  },
  {
    path: 'voice-call',
    loadChildren: () =>
      import('./voicecall/voicecall.module').then((m) => m.VoicecallModule),
  },
  {
    path: 'call',
    loadChildren: () =>
      import('./call-module/call.module').then((m) => m.CallModule),
  },
  {
    path: 'invoice-details/:id',
    loadChildren: () =>
      import('./invoice-details/invoice-details.module').then(
        (m) => m.InvoiceDetailsModule
      ),
  },
  // {
  //   path: 'privacy-policy',
  //   loadChildren: () =>
  //     import('./privacy-policy/privacy-policy.module').then(
  //       (m) => m.PrivacyPolicyModule
  //     ),
  // },
  {
    path: 'terms-conditions',
    loadChildren: () =>
      import('./terms-conditions/terms-conditions.module').then(
        (m) => m.TermsConditionsModule
      ),
  },
  {
    path: 'agora-login/:id',
    loadChildren: () =>
      import('./agora-login/agora-login.module').then(
        (m) => m.AgoraLoginModule
      ),
  },
  {
    path: 'agora-calls/:id',
    loadChildren: () =>
      import('./agora/agora.module').then((m) => m.AgoraModule),
  },

  {
    path: 'patients',
    canActivate: [RoleGuard],
    data: {
      expectedRole: ['user', 'user'],
    },
    loadChildren: () =>
      import('./patients/patients.module').then((m) => m.PatientsModule),
  },
  {
    path: 'doctor',
    canActivate: [RoleGuard],
    data: {
      expectedRole: ['doctor-candidate', 'doctor'],
      // expectedRole: 'doctor-candidate',
    },
    loadChildren: () =>
      import('./doctor/doctor.module').then((m) => m.DoctorModule),
  },

  {
    path: 'admin',
    canActivate: [RoleGuard],
    data: {
      expectedRole: ['admin', 'admin'],
    },
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },

  {
    path: 'call-center',
    canActivate: [RoleGuard],
    data: {
      expectedRole: ['call-center', 'call-center'],
    },
    loadChildren: () =>
      import('./call-center/call-center.module').then(
        (m) => m.CallCenterModule
      ),
  },

  {
    path: 'hospital-admin',
    canActivate: [RoleGuard],
    data: {
      expectedRole: ['hospital-admin', 'hospital-admin'],
    },
    loadChildren: () =>
      import('./hospital-admin/hospital-admin.module').then(
        (m) => m.HospitalAdminModule
      ),
  },

  {
    path: 'thy-admin',
    canActivate: [RoleGuard],
    data: {
      expectedRole: ['thy-admin', 'thy-admin'],
    },
    loadChildren: () =>
      import('./thy-admin/thy-admin.module').then((m) => m.ThyAdminModule),
  },

  /*{
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },*/
  {
    path: 'pharmacy-admin',
    loadChildren: () =>
      import('./pharmacy-admin/pharmacy-admin.module').then(
        (m) => m.PharmacyAdminModule
      ),
  },
  {
    path: 'add-credit',
    loadChildren: () =>
      import('./credit/credit.module').then((m) => m.CreditModule),
  },
  {
    path: 'solutions',
    loadChildren: () =>
      import('./information-pages/solutions/solutions.module').then(
        (m) => m.SolutionsModule
      ),
  },
  {
    path: 'hospital-page/:slug',
    loadChildren: () =>
      import('./hospital-page/hospital-page.module').then(
        (m) => m.HospitalPageModule
      ),
  },
  {
    path: 'avicerna',
    component: AvicernaComponent,
  },
  {
    path: 'obesity',
    component: ObesityComponent,
  },
  {
    path: 'thy',
    component: ThyComponent,
  },
  {
    path: 'become-a-provider',
    component: BecomeProviderComponent,
  },
  {
    path: 'disclaimer',
    component: DisclaimerComponent,
  },
  {
    path: 'informed-consent',
    component: InformedConsentComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent,
  },
  {
    path: 'executive-team',
    component: ExecutiveTeamComponent,
  },
  {
    path: 'who-we-are',
    component: WhoWeAreComponent,
  },
  {
    path: 'kvkk',
    component: KVKKComponent,
  },
  {
    path: 'partners',
    component: PartnersComponent,
  },
  {
    path: 'blog-header',
    component: BlogComponent,
  },
  {
    path: 'hospital-and-private-clinics',
    component: HospitalAndClinicsComponent,
  },
  {
    path: 'government',
    component: GovernmentComponent,
  },
  {
    path: 'how-it-works',
    component: HowItWorksComponent,
  },
  {
    path: 'frequently-asked-questions',
    component: FrequentlyAskedQuestionsComponent,
  },
  {
    path: 'transport-companies',
    component: PrivateTransportCompaniesComponent,
  },
  {
    path: 'hotels-rentals-tours',
    component: HotelsRentalsToursComponent,
  },
  {
    path: 'our-providers',
    component: WhatWeTreatComponent,
  },
  {
    path: 'what-we-treat',
    component: OurProvidersComponent,
  },
  {
    path: 'pharmacies-labs',
    component: PharmaciesLabsComponent,
  },
  {
    path: 'covid',
    component: CovidComponent,
  },
  {
    path: 'insurance-companies',
    component: InsuranceComponent,
  },
  {
    path: 'travel-with-VERADoctors',
    component: TravelComponent,
  },
  {
    path: 'work-in-progress',
    component: WorkInProgressComponent,
  },

  { path: '', redirectTo: 'home-page', pathMatch: 'full' },
  { path: '**', redirectTo: 'home-page', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
      // scrollPositionRestoration: 'disabled' ,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
