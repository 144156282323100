<meta http-equiv="content-type" content="text/html; charset=utf-8" />
<title></title>
<meta name="generator" content="LibreOffice 7.3.7.2 (Linux)" />
<style type="text/css">
  @page {
    size: 8.27in 11.69in;
    margin: 0.79in;
  }
  p {
    line-height: 115%;
    text-align: left;
    orphans: 2;
    widows: 2;
    margin-bottom: 0.1in;
    direction: ltr;
    background: transparent;
  }
  p.western {
    font-family: "Times New Roman", serif;
    font-size: 12pt;
  }
  p.cjk {
    font-size: 12pt;
  }
  p.ctl {
    font-family: "Times New Roman";
    font-size: 12pt;
  }
  a:link {
    color: #0563c1;
    text-decoration: underline;
  }
  em {
    font-style: italic;
  }
</style>
<p
  class="western"
  align="center"
  style="line-height: 106%; margin-bottom: 0.11in"
></p>
<meta http-equiv="content-type" content="text/html; charset=utf-8" />
<title></title>
<meta name="generator" content="LibreOffice 7.3.7.2 (Linux)" />
<style type="text/css">
  @page {
    size: 8.27in 11.69in;
    margin: 0.79in;
  }
  p {
    line-height: 115%;
    text-align: left;
    orphans: 2;
    widows: 2;
    margin-bottom: 0.1in;
    direction: ltr;
    background: transparent;
  }
  p.western {
    font-family: "Times New Roman", serif;
    font-size: 12pt;
  }
  p.cjk {
    font-size: 12pt;
  }
  p.ctl {
    font-family: "Times New Roman";
    font-size: 12pt;
  }
  a:link {
    color: #0563c1;
    text-decoration: underline;
  }
  em {
    font-style: italic;
  }
</style>
<p
  class="western"
  align="center"
  style="line-height: 106%; margin-bottom: 0.11in"
></p>
<meta http-equiv="content-type" content="text/html; charset=utf-8" />
<title></title>
<meta name="generator" content="LibreOffice 7.3.7.2 (Linux)" />
<style type="text/css">
  @page {
    size: 8.27in 11.69in;
    margin: 0.79in;
  }
  p {
    line-height: 115%;
    text-align: left;
    orphans: 2;
    widows: 2;
    margin-bottom: 0.1in;
    direction: ltr;
    background: transparent;
  }
  p.western {
    font-family: "Times New Roman", serif;
    font-size: 12pt;
  }
  p.cjk {
    font-size: 12pt;
  }
  p.ctl {
    font-family: "Times New Roman";
    font-size: 12pt;
  }
  a:link {
    color: #0563c1;
    text-decoration: underline;
  }
  em {
    font-style: italic;
  }
</style>
<p
  class="western"
  align="center"
  style="line-height: 106%; margin-bottom: 0.11in"
>
  <b>VERA PREMIUM CARE SAĞLIK HİZMETLERİ A.Ş</b>
</p>
<p
  align="center"
  style="
    line-height: 100%;
    text-indent: 0.39in;
    margin-top: 0.19in;
    margin-bottom: 0in;
  "
>
  <b>6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA</b>
</p>
<p
  align="center"
  style="
    line-height: 100%;
    text-indent: 0.39in;
    margin-top: 0.19in;
    margin-bottom: 0in;
  "
>
  <b><span style="text-transform: uppercase">HASTA AYDINLATMA METNİ</span></b>
</p>
<p
  align="justify"
  style="
    line-height: 100%;
    text-indent: 0.39in;
    margin-top: 0.17in;
    margin-bottom: 0.17in;
  "
>
  <a name="_Hlk112165071"></a>
  <font color="#000000"
    >Vera Premium Care Sağlık Hizmetleri A.Ş veri sorumlusu olarak, 6698 Sayılı
    Kişisel Verilerin Korunması Kanunu ("</font
  ><b><font color="#000000">Kanun</font></b
  ><font color="#000000"
    >") uyarınca, işbu aydınlatma metni ile kişisel verilerin toplanma yöntemi,
    işleme amaçları, üçüncü kişilere aktarılma halleri ve ilgili kişinin hakları
    konusunda bilgilendirme yapmaktadır.</font
  >
</p>
<p
  align="justify"
  style="
    line-height: 100%;
    text-indent: 0.39in;
    margin-top: 0.17in;
    margin-bottom: 0.17in;
  "
>
  <b>Hasta&nbsp;Aydınlatma Metninin Kapsamı ve İlgili Mevzuat</b>
</p>
<p
  align="justify"
  style="
    line-height: 100%;
    text-indent: 0.39in;
    margin-top: 0.19in;
    margin-bottom: 0in;
  "
>
  İşbu Aydınlatma Metni, aşağıdaki konu başlıklarını kapsamaktadır;
</p>
<ol>
  <li>
    <p
      class="western"
      style="line-height: 100%; margin-top: 0.19in; margin-bottom: 0in"
    >
      Kişisel Verilerin Toplanma Yöntemleri,
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 100%; margin-bottom: 0in">
      İşlenen Kişisel Veriler, İşleme Amaçları ve Saklama Süreleri
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 100%; margin-bottom: 0in">
      İşlenen Kişisel Verilerin Hukuki Sebepleri
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 100%; margin-bottom: 0in">
      Kişisel Verilerin Aktarılması,
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 100%; margin-bottom: 0in">
      İlgili Kişinin Hakları,
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 100%; margin-bottom: 0.19in">
      Sonuç
    </p>
  </li>
</ol>
<p
  align="justify"
  style="
    line-height: 100%;
    text-indent: 0.39in;
    margin-top: 0.19in;
    margin-bottom: 0in;
  "
>
  <font color="#000000">Vera Premium Care Sağlık Hizmetleri A.Ş </font
  >Elektronik&nbsp;Ticaret Kanunu, Savunma Sanayi Kanunu,&nbsp;6102 sayılı Türk
  Ticaret Kanunu, 4857 sayılı İş Kanunu, 6098 sayılı Borçlar Kanunu 5237 sayılı
  Türk Ceza Kanunu ve tabi olunan sair mevzuat kapsamındaki yükümlülüklerini
  yerine getirmek maksadıyla kişisel verileri işlemektedir.
</p>
<p
  align="justify"
  style="line-height: 100%; margin-top: 0.19in; margin-bottom: 0in"
>
  <b>1.</b> <b>Kişisel Verilerin Toplanma Yöntemleri&nbsp;</b>
</p>
<p
  align="justify"
  style="
    line-height: 100%;
    text-indent: 0.39in;
    margin-top: 0.19in;
    margin-bottom: 0in;
  "
>
  <font color="#000000">Vera Premium Care Sağlık Hizmetleri A.Ş</font>, kişisel
  verileri özellikle <b>yazılı sözleşme,</b><em><b> </b></em
  ><b
    >e-posta, fiziken evrak teslimi, kamera, web sitesi,&nbsp;idari ve adli
    makamlardan gelen tebligatlar</b
  ><font color="#ff0000">&nbsp;</font>ve nadiren sair iletişim kanalları
  vasıtasıyla işitsel, elektronik veya fiziki yazılı olarak, Kanunda belirtilen
  kişisel veri işleme şartlarına uygun olarak ve işbu
  <font color="#000000">Vera Premium Care Sağlık Hizmetleri A.Ş</font>
  Aydınlatma Metni’nde belirtilen hukuki sebepler doğrultusunda toplamaktadır.
</p>
<p
  align="justify"
  style="
    line-height: 100%;
    text-indent: 0.39in;
    margin-top: 0.19in;
    margin-bottom: 0in;
  "
>
  <br />
</p>
<p
  class="western"
  style="line-height: 100%; margin-top: 0.19in; margin-bottom: 0.19in"
>
  <b>2. İşlenen Kişisel Veriler ve İşleme Amaçları</b>
</p>
<ol>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <b>Kimlik Kategorisi</b>
    </p>
  </li>
</ol>
<ol type="a">
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <b>Veriler</b>
    </p>
  </li>
</ol>
<p class="western" style="line-height: 106%; margin-bottom: 0.11in">
  Ad, Soy Ad
</p>
<ol type="a" start="2">
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <b>Veri İşleme Amaçları</b>
    </p>
  </li>
</ol>
<ul>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Faaliyetlerin Mevzuata Uygun Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <a name="_Hlk48811036"></a>
      <font size="2" style="font-size: 11pt"
        >Finans ve Muhasebe İşlerinin Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Firma / Ürün / Hizmetlere Bağlılık Süreçlerinin Yürütülmesi
      </font>
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <a name="_Hlk48814492"></a>
      <font size="2" style="font-size: 11pt"
        >Hasta İlişkileri Yönetimi Süreçlerinin Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <a name="_Hlk48817703"></a>
      <font size="2" style="font-size: 11pt"
        >Hasta Memnuniyetine Yönelik Aktivitelerin Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <a name="_Hlk48816579"></a>
      <font size="2" style="font-size: 11pt"
        >Hizmet Faaliyetlerinin Yürütülmesi ve Denetimi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Hukuk İşlerinin Takibi ve Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt">İlaç veya Tıbbi Cihaz Temini</font>
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >İletişim Faaliyetlerinin Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >İlgili Mevzuatlar Uyarınca Sağlık Bakanlığı ve Diğer Kamu Kurum ve
        Kuruluşları ile Talep Edilen Bilgilerin Paylaşılması</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Kamu Sağlığının Korunması, Tıbbi Teşhis, Tedavi ve Bakım Hizmetlerinin
        Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Mal / Hizmet Satın Alım Süreçlerinin Yürütülmesi&nbsp;</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Mal / Hizmet Satış Sonrası Destek Hizmetlerinin Yürütülmesi
      </font>
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <a name="_Hlk112164898"></a>
      <font size="2" style="font-size: 11pt"
        >Mal / Hizmet Satış Süreçlerinin Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Mal / Hizmet Üretim ve Operasyon Süreçlerinin Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi
      </font>
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Müşteri Memnuniyetine Yönelik Aktivitelerin Yürütülmesi
      </font>
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Reklam / Kampanya / Promosyon Süreçlerinin Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <a name="_Hlk48814786"></a>
      <font size="2" style="font-size: 11pt"
        >Saklama ve Arşiv Faaliyetlerinin Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Sözleşme Süreçlerinin Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Üyenin Profil Sayfasının Oluşturulması ve Almak İstediği Online Sağlık
        Hizmeti ile İlgili Kendisi ile İletişime Geçilebilmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Yetkili Kişi, Kurum ve Kuruluşlara Bilgi Verilmesi</font
      >
    </p>
  </li>
</ul>
<p
  class="western"
  style="
    line-height: 100%;
    text-indent: 0.3in;
    margin-top: 0.19in;
    margin-bottom: 0.19in;
  "
>
  <span style="font-weight: normal"
    >c. Saklama Süresi: Hizmet İlişkisinin Bitimini Takiben 10 Yıl</span
  >
</p>
<p
  class="western"
  style="
    font-weight: normal;
    line-height: 100%;
    text-indent: 0.3in;
    margin-top: 0.19in;
    margin-bottom: 0.19in;
  "
>
  <br />
  <br />
</p>
<p class="western" style="line-height: 106%; margin-bottom: 0.11in">
  <b>2- İletişim Kategorisi</b>
</p>
<ol type="a">
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <b>Veriler</b>
    </p>
  </li>
</ol>
<p class="western" style="line-height: 106%; margin-bottom: 0.11in">
  E-Posta Adresi, Telefon No, Şifre Bilgileri
</p>
<ol type="a" start="2">
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <b>Veri İşleme Amaçları</b>
    </p>
  </li>
</ol>
<ul>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Alınan randevulara ilişkin bilgilerin hastalara sağlanması</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Faaliyetlerin mevzuata uygun yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"><span>Firma / </span></font
      ><font size="2" style="font-size: 11pt"><span>Ü</span></font
      ><font size="2" style="font-size: 11pt"
        ><span>rün / Hizmetlere Bağlılık Süreçlerinin Yürütülmesi,</span></font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Hasta ilişkileri yönetimi süreçlerinin yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Hasta memnuniyetine yönelik aktivitelerin yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Hizmet faaliyetlerinin yürütülmesi ve denetimi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Hizmet kalitesini arttırmak amacıyla hasta memnuniyetinin ölçülmesi ve
        araştırılmasına ilişkin faaliyetler</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Hizmet operasyon süreçlerinin yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Hizmet satış süreçlerinin yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        ><span>Hukuk İşlerinin Takibi Ve Yürütülmesi, </span></font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >İletişim Faaliyetlerinin Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Kamu sağlığının korunması, tıbbi teşhis, tedavi ve bakım hizmetlerinin
        yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        ><span
          >Kullanıcıların ayrıca onay vermeleri halinde, verilen onayın
          kaldırılmasına kadarki sü</span
        ></font
      ><font size="2" style="font-size: 11pt"><span>re i</span></font
      ><font size="2" style="font-size: 11pt"><span>çin </span></font
      ><font size="2" style="font-size: 11pt"
        >reklam / kampanya / promosyon süreçlerinin yürütülmesi</font
      ><font size="2" style="font-size: 11pt"><span> </span></font>
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        ><span
          >Mal / Hizmet Satış Sonrası Destek Hizmetlerinin Yürütülmesi,
        </span></font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        ><span>Mal / Hizmet Satış Süreçlerinin Yürütülmesi,</span></font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"><span>Mal / Hizmet </span></font
      ><font size="2" style="font-size: 11pt"><span>Ü</span></font
      ><font size="2" style="font-size: 11pt"
        ><span>retim Ve Operasyon Süreçlerinin Yürütülmesi, </span></font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        ><span>Müşteri İlişkileri Y</span></font
      ><font size="2" style="font-size: 11pt"><span>ö</span></font
      ><font size="2" style="font-size: 11pt"
        ><span>netimi Süreçlerinin Yürütülmesi, </span></font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        ><span>Müşteri Memnuniyetine Y</span></font
      ><font size="2" style="font-size: 11pt"><span>ö</span></font
      ><font size="2" style="font-size: 11pt"
        ><span>nelik Aktivitelerin Yürütülmesi, </span></font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        ><span
          >Reklam / Kampanya / Promosyon Süreçlerinin Yürütülmesi,
        </span></font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Saklama ve arşiv faaliyetlerinin yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"><span>S</span></font
      ><font size="2" style="font-size: 11pt"><span>ö</span></font
      ><font size="2" style="font-size: 11pt"><span>zleş</span></font
      ><font size="2" style="font-size: 11pt"><span>me S</span></font
      ><font size="2" style="font-size: 11pt"
        ><span>üreçlerinin Yürütülmesi, </span></font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"><span>Ü</span></font
      ><font size="2" style="font-size: 11pt"
        ><span
          >yenin profil sayfasının oluşturulması ve almak istediği online sağlık
          hizmeti ile ilgili kendisi ile iletişime geçilebilmesi</span
        ></font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi</font
      >
    </p>
  </li>
</ul>
<p
  class="western"
  style="line-height: 106%; margin-left: 0.3in; margin-bottom: 0.11in"
>
  <br />
  <br />
</p>
<p
  class="western"
  style="
    line-height: 100%;
    text-indent: 0.3in;
    margin-top: 0.19in;
    margin-bottom: 0.19in;
  "
>
  <b>c. Saklama Süresi:</b
  ><span style="font-weight: normal">
    Hizmet İlişkisinin Bitimini Takiben 10 Yıl</span
  >
</p>
<p
  class="western"
  style="
    line-height: 100%;
    text-indent: 0.3in;
    margin-top: 0.19in;
    margin-bottom: 0.19in;
  "
>
  <b>3- Müşteri İşlem Kategorisi</b>
</p>
<ol type="a">
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <b>Veriler</b>
    </p>
  </li>
</ol>
<p class="western" style="line-height: 106%; margin-bottom: 0.11in">Fatura</p>
<ol type="a" start="2">
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <b>Veri İşleme Amaçları</b>
    </p>
  </li>
</ol>
<ul>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      Faaliyetlerin mevzuata uygun yürütülmesi
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Finans ve muhasebe işlerinin yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Hasta ilişkileri yönetimi süreçlerinin yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Hizmet faaliyetlerinin yürütülmesi / denetimi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Hukuk işlerinin takibi ve yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >İlgili mevzuatlar uyarınca sağlık bakanlığı ve diğer kamu kurum ve
        kuruluşları ile talep edilen bilgilerin paylaşılması</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Mal / Hizmet Satış Süreçlerinin Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Mal / Hizmet Üretim ve Operasyon Süreçlerinin Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Sağlık hizmetinin finansmanının sağlanması, faturalandırma
        yapılması</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Saklama ve arşiv faaliyetlerinin yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi</font
      >
    </p>
  </li>
</ul>
<p
  class="western"
  style="
    line-height: 100%;
    text-indent: 0.3in;
    margin-top: 0.19in;
    margin-bottom: 0.19in;
  "
>
  <b>c. Saklama Süresi: </b
  ><span style="font-weight: normal"
    >Hizmet İlişkisinin Bitimini Takiben 10 Yıl</span
  >
</p>
<p
  class="western"
  style="
    font-weight: normal;
    line-height: 100%;
    text-indent: 0.3in;
    margin-top: 0.19in;
    margin-bottom: 0.19in;
  "
>
  <br />
  <br />
</p>
<p class="western" style="line-height: 106%; margin-bottom: 0.11in">
  <b>4- Online Sağlık Hizmeti Kategorisi</b>
</p>
<ol type="a">
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <b>Veriler</b>
    </p>
  </li>
</ol>
<p class="western" style="line-height: 106%; margin-bottom: 0.11in">
  Kamera Kayıtları, Ses Kayıtları
</p>
<ol type="a" start="2">
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <b>Veri İşleme Amaçları</b>
    </p>
  </li>
</ol>
<p
  class="western"
  style="line-height: 106%; margin-left: 0.5in; margin-bottom: 0.11in"
>
  <br />
  <br />
</p>
<ul>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Hasta ilişkileri yönetimi süreçlerinin yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >İlgili mevzuatlar uyarınca sağlık bakanlığı ve diğer kamu kurum ve
        kuruluşları ile talep edilen bilgilerin paylaşılması</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        ><span
          >İleride ortaya çıkabilecek hukuki uyuşmazlıklarla ilgili adli
          mercilerin talepleri halinde yasal mevzuat çerçevesinde ilgili adli
          mercilere iletmek</span
        ></font
      >
    </p>
  </li>
</ul>
<ol type="a" start="3">
  <li>
    <p style="line-height: 100%; margin-top: 0.19in; margin-bottom: 0.19in">
      <b>Saklama Süresi:</b
      ><span style="font-weight: normal">
        Hizmet İlişkisinin Bitimi ile Alınan Verilerin Sağlık Bakanlığı’na
        iletilmesine kadar</span
      >
    </p>
  </li>
</ol>
<p
  style="
    font-weight: normal;
    line-height: 100%;
    margin-left: 0.5in;
    margin-top: 0.19in;
    margin-bottom: 0.19in;
  "
>
  <br />
  <br />
</p>
<p
  style="
    font-weight: normal;
    line-height: 100%;
    margin-left: 0.5in;
    margin-top: 0.19in;
    margin-bottom: 0.19in;
  "
>
  <br />
  <br />
</p>
<p class="western" style="line-height: 106%; margin-bottom: 0.11in">
  <b>5- İşlem Güvenliği Kategorisi</b>
</p>
<ol type="a">
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <b>Veriler</b>
    </p>
  </li>
</ol>
<p
  class="western"
  style="line-height: 106%; margin-left: 0.25in; margin-bottom: 0.11in"
>
  IP Adresi, Erişim Logları, Tarayıcı Bilgileri, Web Site Çerez Kayıtları
</p>
<ol type="a" start="2">
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <b>Veri İşleme Amaçları</b>
    </p>
  </li>
</ol>
<ul>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 106%; margin-bottom: 0.11in"
    >
      <font size="2" style="font-size: 11pt"
        >Hizmet Operasyon Süreçlerinin Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >İlgili Mevzuatlar Uyarınca Sağlık Bakanlığı ve Diğer Kamu Kurum ve
        Kuruluşları ile Talep Edilen Bilgilerin Paylaşılması</font
      >
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 106%; margin-bottom: 0.11in"
    >
      <font size="2" style="font-size: 11pt"
        >Kısa, Orta ve Uzun Vadede Ticari Politikaların Tespit Edilmesi,
        Planlanması ve Uygulanması,
      </font>
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 106%; margin-bottom: 0.11in"
    >
      <font size="2" style="font-size: 11pt"
        >Müşteri İlişkileri ve Kurumsal İletişim Süreçlerinin Yönetilmesi
      </font>
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 106%; margin-bottom: 0.11in"
    >
      <font size="2" style="font-size: 11pt">Talep / Şikayetlerin Takibi</font>
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 106%; margin-bottom: 0.11in"
    >
      <font size="2" style="font-size: 11pt"
        >Veradoctors.Com’un İlgili Mevzuattan Doğan Yükümlülüklerin Yerine
        Getirilmesi,
      </font>
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 106%; margin-bottom: 0.11in"
    >
      <font size="2" style="font-size: 11pt"
        >Veradoctors.Com'un İş İlişkisi İçerisinde Olduğu Gerçek ve Tüzel
        Kişilerin Ticari ve Hukuki Emniyetinin Sağlanması</font
      >
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 106%; margin-bottom: 0.11in"
    >
      <font size="2" style="font-size: 11pt"
        >Yürütülen Ticari Faaliyetlerin Mevzuata ve Politikalarımıza Uygun
        Olarak Yerine Getirilmesi İçin Veradoctors.Com İş Birimleri Tarafından
        Gerekli Çalışmaların Yapılması ve Bu Doğrultuda Faaliyetlerin
        Yürütülmesi</font
      >
    </p>
  </li>
</ul>
<ol type="a" start="3">
  <li>
    <p style="line-height: 100%; margin-top: 0.19in; margin-bottom: 0.19in">
      <a name="_Hlk112165799"></a> <b>Saklama Süresi:</b
      ><span style="font-weight: normal"> 10 Yıl</span>
    </p>
  </li>
</ol>
<p class="western" style="line-height: 106%; margin-bottom: 0.11in">
  <b>6- Sağlık Bilgileri Kategorisi</b>
</p>
<ol type="a">
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <b>Veriler</b>
    </p>
  </li>
</ol>
<p
  class="western"
  align="justify"
  style="line-height: 106%; margin-bottom: 0.11in"
>
  Kişisel sağlık bilgileri, Tıbbi Şikayete İlişkin Beyanlar
</p>
<ol type="a" start="2">
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 106%; margin-bottom: 0.11in"
    >
      <b>Veri İşleme Amaçları</b>
    </p>
  </li>
</ol>
<ul>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 106%; margin-bottom: 0.11in"
    >
      <font size="2" style="font-size: 11pt"
        >Faaliyetlerin Mevzuata Uygun Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Hizmet Kalitesini Arttırmak Amacıyla Hasta Memnuniyetinin Ölçülmesi ve
        Araştırılmasına İlişkin Faaliyetler</font
      >
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 106%; margin-bottom: 0.11in"
    >
      <font size="2" style="font-size: 11pt"
        >Hizmet Operasyon Süreçlerinin Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >İlgili Mevzuatlar Uyarınca Sağlık Bakanlığı ve Diğer Kamu Kurum ve
        Kuruluşları ile Talep Edilen Bilgilerin Paylaşılması</font
      >
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 106%; margin-bottom: 0.11in"
    >
      <font size="2" style="font-size: 11pt"
        >İnternet Sitesinin Verdiği Online Sağlık Hizmetinin Yürütülmesi
        Kapsamında Kullanıcının Doğru Uzman ile Buluşturulması</font
      >
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 106%; margin-bottom: 0.11in"
    >
      <font size="2" style="font-size: 11pt"
        >Kamu Sağlığının Korunması, Tıbbi Teşhis, Tedavi ve Bakım Hizmetlerinin
        Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 106%; margin-bottom: 0.11in"
    >
      <a name="_Hlk112496740"></a>
      <font size="2" style="font-size: 11pt"
        >Mal / Hizmet Satış Sonrası Destek Hizmetlerinin Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 106%; margin-bottom: 0.11in"
    >
      <font size="2" style="font-size: 11pt"
        >Mal / Hizmet Satış Süreçlerinin Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 106%; margin-bottom: 0.11in"
    >
      <font size="2" style="font-size: 11pt"
        >Mal / Hizmet Üretim ve Operasyon Süreçlerinin Yürütülmesi</font
      >
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 106%; margin-bottom: 0.11in">
      <font size="2" style="font-size: 11pt"
        >Sağlık Hizmetlerini Geliştirme Amacıyla Analiz Yapılması</font
      >
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 106%; margin-bottom: 0.11in"
    >
      <font size="2" style="font-size: 11pt">Talep / Şikayetlerin Takibi</font>
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 106%; margin-bottom: 0.11in"
    >
      <font size="2" style="font-size: 11pt"
        >Yetkili Kişi, Kurum ve Kuruluşlara Bilgi Verilmesi</font
      >
    </p>
  </li>
</ul>
<p
  class="western"
  style="
    line-height: 100%;
    margin-left: 0.25in;
    margin-top: 0.19in;
    margin-bottom: 0.19in;
  "
>
  <b>c. Saklama Süresi:</b
  ><span style="font-weight: normal">
    Hizmet İlişkisinin Bitimi ile Alınan Verilerin Sağlık Bakanlığı’na
    iletilmesine kadar</span
  >
</p>
<p
  style="
    font-weight: normal;
    line-height: 100%;
    margin-left: 0.5in;
    margin-top: 0.19in;
    margin-bottom: 0.19in;
  "
>
  <br />
  <br />
</p>
<p
  class="western"
  style="line-height: 100%; margin-top: 0.19in; margin-bottom: 0.19in"
>
  <b>3. İşlenen Kişisel Verilerin Hukuki Sebepleri</b>
</p>
<p
  align="justify"
  style="line-height: 100%; margin-top: 0.19in; margin-bottom: 0.19in"
>
  <font color="#000000"
    >Vera Premium Care Sağlık Hizmetleri A.Ş veri sorumlusu olarak, </font
  >Şirket’in tabi olduğu mevzuatta öngörülmüş olması, bir sözleşmenin kurulması
  veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına
  ait kişisel verilerin işlenmesinin gerekli olması, talep edilen ürün ve
  hizmetleri sunabilmek veya akdedilen sözleşmelerin gereğinin yerine
  getirilmesi, Şirket’in hukuki yükümlülüklerini yerine getirebilmesi için veri
  işlemenin zorunlu olması, Şirket’in mevzuat veya iç uygulayışı gereği bir
  hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması,
  ilgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Şirket’in
  meşru menfaatleri için veri işlemenin gerekli olması nedenleriyle veri
  işlemektedir.
</p>
<p
  align="justify"
  style="line-height: 100%; margin-top: 0.19in; margin-bottom: 0in"
>
  <b>4. Kişisel Verilerin Aktarılması</b>
</p>
<ol>
  <ol type="A">
    <li>
      <p
        class="western"
        style="line-height: 100%; margin-top: 0.19in; margin-bottom: 0.19in"
      >
        <b>Yurtiçine Aktarım</b>
      </p>
    </li>
  </ol>
</ol>
<p
  align="justify"
  style="
    line-height: 100%;
    text-indent: 0.39in;
    margin-top: 0.19in;
    margin-bottom: 0.1in;
  "
>
  <span style="background: #ffffff">&nbsp;&nbsp;</span
  ><font color="#000000"
    >Vera Premium Care Sağlık Hizmetleri A.Ş veri sorumlusu olarak</font
  ><span style="background: #ffffff"
    >, işbu Aydınlatma Metninde belirtilen amaçlar doğrultusunda Kanunun 8.
    maddesine uygun olarak, gerekli bütün önlemleri almak kaydıyla sağlık
    kategorisi altındaki özel nitelikli kişisel veriler haricindeki diğer
    kişisel verileri aşağıdaki alıcı gruplarıyla paylaşabilir;</span
  >
</p>
<ul>
  <li>
    <p
      class="western"
      style="line-height: 100%; margin-top: 0.19in; margin-bottom: 0in"
    >
      Düzenleyici ve denetleyici kurumlar ile mahkeme ve icra müdürlükleri gibi
      ilgili kişinin kişisel verilerini talep etmeye yetkili başta Sağlık
      Bakanlığı olmak üzere kurum veya kuruluşlar ile bunların belirlediği
      kişiler
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 100%; margin-bottom: 0.11in"
    >
      <span>Mevzuatta izin verilen </span><span>ö</span
      ><span>zel hukuk tüzel ve gerçek kişilerine,</span>
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 100%; margin-bottom: 0.11in"
    >
      <span>Veradoctors.com</span>’<span
        >un denetime yetkili kurum ve kuruluşlara,
      </span>
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 100%; margin-bottom: 0.11in"
    >
      <span>Ö</span
      ><span
        >demelerin ve mali yükümlülüklerin yerine getirilmesi adına anlaşmalı
        olunan </span
      ><span>ö</span
      ><span>deme kuruluşları ile avukat ve hukuki danışmanlara,</span>
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 100%; margin-bottom: 0.11in"
    >
      <span>Veradoctors.com</span>’<span
        >un faaliyetlerinin yürütülmesi ve geliştirilmesi için hizmet alınan
        veya işbirliğ</span
      ><span>i i</span><span>çerisinde olunan iş ortaklarına</span>
    </p>
  </li>
  <li>
    <p
      class="western"
      align="justify"
      style="line-height: 100%; margin-bottom: 0.11in"
    >
      <span>Veradoctors.com</span>’<span>un tedarikçilerine</span>
    </p>
  </li>
</ul>
<p
  class="western"
  align="justify"
  style="line-height: 100%; margin-bottom: 0.11in"
>
  <br />
  <br />
</p>
<p
  class="western"
  align="justify"
  style="line-height: 100%; text-indent: 0.25in; margin-bottom: 0.11in"
>
  <font color="#000000"
    >Vera Premium Care Sağlık Hizmetleri A.Ş veri sorumlusu olarak</font
  >, özel nitelikli kişisel veri kategorisinde yer alan verilerden olan,
</p>
<ul>
  <li>
    <p align="justify" style="line-height: 100%; margin-bottom: 0.11in">
      sağlık verileri ile online sağlık hizmeti<span> g</span
      ><span>ö</span>rüşmesi esnasında gerçekleştirilen sesli ve
      g<span>ö</span>rüntülü kayıtları sadece kullanıcının hizmet almak istediği
      tıp uzmanı ile paylaşmaktadır. Özel nitelikli kişisel veriler kullanıcının
      hizmet almak ve g<span>ö</span>rüşmek istediği tıp uzmanı haricinde
      başkaca üçüncü ş<span>ah</span>ıslarla paylaşılmamaktadır.
    </p>
  </li>
</ul>
<p
  class="western"
  align="justify"
  style="line-height: 100%; margin-bottom: 0.11in"
>
  <br />
  <br />
</p>
<p
  class="western"
  align="justify"
  style="line-height: 100%; margin-bottom: 0.11in"
>
  <br />
  <br />
</p>
<p
  class="western"
  align="justify"
  style="line-height: 100%; margin-bottom: 0.11in"
>
  Veri Aktarım Amaçları;
</p>
<ul>
  <li>
    <p align="justify" style="line-height: 100%; margin-bottom: 0.11in">
      İlgili mevzuatlar uyarınca Sağlık Bakanlığı ve diğer kamu kurum ve
      kuruluşları ile talep edilen bilgilerin paylaşılması
    </p>
  </li>
  <li>
    <p align="justify" style="line-height: 100%; margin-bottom: 0.11in">
      Kamu sağlığının korunması, tıbbi teşhis, tedavi ve bakım hizmetlerinin
      yürütülmesi
    </p>
  </li>
  <li>
    <p align="justify" style="line-height: 100%; margin-bottom: 0.11in">
      Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi
    </p>
  </li>
</ul>
<p
  class="western"
  align="justify"
  style="line-height: 100%; margin-bottom: 0.11in"
>
  <br />
  <br />
</p>
<p
  class="western"
  style="
    line-height: 100%;
    margin-left: 0.75in;
    margin-top: 0.19in;
    margin-bottom: 0.19in;
  "
>
  <b>B. Yurtdışına Aktarım</b>
</p>
<p
  align="justify"
  style="
    line-height: 100%;
    text-indent: 0.39in;
    margin-top: 0.19in;
    margin-bottom: 0in;
  "
>
  <font color="#000000">Vera Premium Care Sağlık Hizmetleri A.Ş</font>, 6698
  sayılı KVK Kanunu’na uygun olarak işlediği
  <font color="#000000">Hasta</font> kişisel verilerini yurt dışına
  aktarmamaktadır.
</p>
<p
  align="justify"
  style="line-height: 100%; margin-top: 0.19in; margin-bottom: 0in"
>
  <b>5. </b><b>İlgili Kişinin Hakları</b>
</p>
<p
  align="justify"
  style="
    line-height: 100%;
    text-indent: 0.39in;
    margin-top: 0.19in;
    margin-bottom: 0.1in;
  "
>
  <font color="#000000">Vera Premium Care Sağlık Hizmetleri A.Ş </font
  >tarafından kişisel verileri işlenen İlgili Kişi, KVK Kanunu’nun 11.
  Maddesinde sayılan aşağıdaki haklarını kullanabilir;
</p>
<ol type="a">
  <li>
    <p
      class="western"
      style="line-height: 100%; margin-top: 0.19in; margin-bottom: 0in"
    >
      Kişisel veri işlenip işlenmediğini öğrenme,
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 100%; margin-bottom: 0in">
      Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 100%; margin-bottom: 0in">
      Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp
      kullanılmadığını öğrenme,
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 100%; margin-bottom: 0in">
      Kişisel sağlık verilerine erişim ve bu verileri isteme,
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 100%; margin-bottom: 0in">
      Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü
      kişileri bilme,
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 100%; margin-bottom: 0in">
      Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
      düzeltilmesini isteme,
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 100%; margin-bottom: 0in">
      &nbsp; KVK Kanunu 7. maddede öngörülen şartlar çerçevesinde kişisel
      verilerin silinmesini veya yok edilmesini isteme,
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 100%; margin-bottom: 0in">
      (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin
      aktarıldığı üçüncü kişilere bildirilmesini isteme,
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 100%; margin-bottom: 0in">
      İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
      edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına
      itiraz etme,
    </p>
  </li>
  <li>
    <p class="western" style="line-height: 100%; margin-bottom: 0.19in">
      Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması
      hâlinde zararın giderilmesini talep etme.
    </p>
  </li>
</ol>
<p
  align="justify"
  style="
    line-height: 100%;
    text-indent: 0.39in;
    margin-top: 0.19in;
    margin-bottom: 0.1in;
  "
>
  İlgili Kişi ‘<font color="#000000"
    ><b>Vera Premium Care Sağlık Hizmetleri A.Ş</b></font
  ><font color="#000000"> </font
  ><b>Kişisel Verileri Korunması ve İşlenmesi Politikası’ </b>uyarınca<b
  > </b>taleplerini, yazılı olarak veya kayıtlı elektronik posta adresi, güvenli
  elektronik imza ve mobil imza suretiyle iletebilecektir. Bunun için, Ad-Soyad,
  İmza, T.C. Kimlik Numarası, Yerleşim Yeri veya İşyeri Adresi, varsa Elektronik
  Posta Adresi, Telefon veya Faks Numarası ile birlikte, kullanılmak istenen
  hakka yönelik açıklamaları içeren başvuru formu ile “Zerzavatçı Mah. Şehit
  Cengiz Cad. No:41A Beykoz / İSTANBUL” adresine başvuru yapılabilir. Ayrıca
  <a href="mailto:info@.....................com"
    ><font color="#0563c1"
      ><u>{{ "info@" }}</u></font
    ><font color="#0563c1"><u>veradoctors.</u></font
    ><font color="#0563c1"><u>com</u></font></a
  >
  e-posta adresi veya 0 850 308 4783 numaralı telefon üzerinden bilgi
  alınabilir.
</p>
<p
  align="justify"
  style="
    line-height: 100%;
    text-indent: 0.39in;
    margin-top: 0.17in;
    margin-bottom: 0.17in;
  "
>
  İlgili kişinin başvurusuna müteakip
  <font color="#000000">Vera Premium Care Sağlık Hizmetleri A.Ş,</font> 30
  günlük yasal süre içinde ilgili kişinin başvurusunu ücretsiz olarak
  sonuçlandıracaktır.
</p>
<p
  align="justify"
  style="
    line-height: 100%;
    text-indent: 0.39in;
    margin-top: 0.19in;
    margin-bottom: 0in;
  "
>
  <font color="#000000">Vera Premium Care Sağlık Hizmetleri A.Ş</font>; Kanunda
  meydana gelebilecek değişiklikler ve Kişisel Verileri Koruma Kurulu tarafından
  belirlenebilecek yeni yöntem veya yönetmelikler sebebiyle, bu aydınlatma
  bildiriminde değişiklik yapma hakkına sahiptir.
</p>
<p
  align="justify"
  style="
    line-height: 100%;
    text-indent: 0.39in;
    margin-top: 0.19in;
    margin-bottom: 0in;
  "
>
  <b>SONUÇ</b>
</p>
<p
  align="justify"
  style="
    line-height: 100%;
    text-indent: 0.39in;
    margin-top: 0.17in;
    margin-bottom: 0.17in;
  "
>
  <font color="#000000">Vera Premium Care Sağlık Hizmetleri A.Ş</font>
  tarafından işlenen kişisel veriler, işbu Aydınlatma Metni ile belirtilen
  amaçlar ve kapsam dışında kullanılmamak kaydıyla gerekli tüm bilgi güvenliği
  tedbirleri de alınarak işlenebilecek; yasal saklama süresince veya işleme
  amacının gerekli kıldığı süre boyunca saklanacak ve işleme amacının gerekli
  kıldığı sürenin sonunda anonimleştirilerek
  <font color="#000000">Vera Premium Care Sağlık Hizmetleri A.Ş</font>
  tarafından kullanılmaya devam edilecek yahut Kanun ve ilgili mevzuata uygun
  olarak
  <font color="#000000"
    >Vera Premium Care Sağlık Hizmetleri A.Ş Kişisel Verileri İmha
    Politikası</font
  >
  gereği imha edilecektir.
</p>
<p
  align="justify"
  style="
    line-height: 100%;
    text-indent: 0.39in;
    margin-top: 0.17in;
    margin-bottom: 0.17in;
  "
>
  <font color="#000000">Vera Premium Care Sağlık Hizmetleri A.Ş</font>, Kanun
  gereğince ilgili kişinin kişisel verilerini hukuka ve dürüstlük kuralına uygun
  olarak doğru ve gerektiğinde güncel olarak, belirli, açık ve meşru amaçlar
  doğrultusunda, işlendikleri amaçla bağlantılı, sınırlı ve ölçülü olarak,
  ilgili mevzuatta öngörülen süre kadar veya işlendikleri amaç için gerekli olan
  süre kadar işlenmesine özellikle dikkat etmekte ve bu amaçla gerekli
  tedbirleri almaktadır.
</p>
