import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { url } from '../../config/http';

@Injectable({
  providedIn: 'root',
})
export class HospitalDetailService {
  constructor(private httpclient: HttpClient) {}

  getHospitalDetailById(hospitalId: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpclient.get(
      url + `/hospital-detail/get-by-id/${hospitalId}`,
      options
    );
  }

  getHospitalDetailBySlug(hospitalSlug: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };
    return this.httpclient.get(
      url + `/hospital-detail/get-by-slug/${hospitalSlug}`,
      options
    );
  }

  getHospitalsSlug() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };
    return this.httpclient.get(url + `/hospital-detail/get-all-slugs`, options);
  }

  addHospitalDetail(body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpclient.post(url + `/hospital-detail`, body, options);
  }

  addHospitalSliders(body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpclient.post(
      url + `/hospital-detail/sliders`,
      body,
      options
    );
  }
  addHospitalServices(body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpclient.post(
      url + `/hospital-detail/services`,
      body,
      options
    );
  }

  addHospitalAboutServices(body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpclient.post(
      url + `/hospital-detail/about-services`,
      body,
      options
    );
  }

  addHospitalHowItWorks(body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpclient.post(
      url + `/hospital-detail/how-it-works`,
      body,
      options
    );
  }

  addHospitalVideo(body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth': localStorage.getItem('xauth'),
      }),
      observe: 'response' as 'response',
    };
    return this.httpclient.post(url + `/hospital-detail/video`, body, options);
  }

  getDoctorsTeamMembers = (hospitalId) => {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response' as 'response',
    };

    return this.httpclient.get(
      url + `/doctors?skip=0&limit=100&hospitalId=${hospitalId || ''}`,
      options
    );
  };

  uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpclient.post(url + `/hospital-detail/upload-file`, formData);
  }
}
