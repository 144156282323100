import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.css'],
})
export class HowItWorksComponent implements OnInit {
  localLanguage =
    localStorage.getItem('language') || window.navigator.language.split('-')[0];

  constructor(public translate: TranslateService) {
    this.translate.onLangChange.subscribe((event) => {
      this.localLanguage = event.lang;
      this.getAppStoreLink(event.lang);
    });
  }

  appleStoreLink: string;

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.getAppStoreLink(this.localLanguage || 'en');
  }

  getAppStoreLink(localLang: string = 'tr'): void {
    const appStoreBaseURL = 'https://apps.apple.com/';
    const appID = '6479270282';
    const local = localLang === 'en' ? 'us' : localLang;

    this.appleStoreLink = `${appStoreBaseURL}${local}/app/veradoctors/id${appID}`;
  }
}
