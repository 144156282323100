<div class="cookie-consent" *ngIf="showConsent" [@slideIn]>
  <div class="content">
    <h3>{{ contentHeader }}</h3>
    <div class="content-message" [innerHTML]="contentMessage"></div>
    <div class="buttons d-flex justify-content-end">
      <button (click)="acceptCookies()" aria-label="Accept cookies">
        {{ acceptText }}
      </button>
    </div>
  </div>
</div>
