import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userLoggedIn = new BehaviorSubject<boolean>(this.isAuthenticated());
  userLoggedIn$ = this.userLoggedIn.asObservable();

  constructor(public jwtHelper: JwtHelperService) {}

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('xauth');

    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  // Kullanıcı durumunu güncellemek için bir metod
  updateAuthStatus(): void {
    this.userLoggedIn.next(this.isAuthenticated());
  }
}
