import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.css'],
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate(
          '0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)',
          style({ transform: 'translateY(0)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        animate(
          '0.3s ease-in-out',
          style({ transform: 'translateY(100%)', opacity: 0 })
        ),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CookieConsentComponent implements OnInit {
  contentHeader: string = '';
  acceptText: string = '';
  contentMessage: string = '';

  constructor(public translate: TranslateService) {
    // Dil değişikliklerini dinlemek için subscribe
    this.translate.onLangChange.subscribe(() => {
      this.updateTranslations();
    });
  }
  showConsent: boolean = true;

  ngOnInit() {
    const currentLang = localStorage.getItem('language') || 'en';
    this.translate.use(currentLang).subscribe(() => {
      this.updateTranslations();
    });

    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'accepted') {
      this.showConsent = false;
    }
  }

  acceptCookies() {
    localStorage.setItem('cookieConsent', 'accepted');
    this.showConsent = false;
  }

  updateTranslations() {
    this.contentHeader = this.translate.instant('acceptPrivacyPolicy');
    this.acceptText = this.translate.instant('I accept');
    this.contentMessage = this.translate.instant(
      'acceptPrivacyPolicycontentMessage'
    );
  }
}
